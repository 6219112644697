import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { initializeAxiosConfigDefaults } from '../service-client/http-interceptors';

initializeAxiosConfigDefaults();

export const emptyPcApi = createApi({
    reducerPath: 'pcApi',
    baseQuery: fetchBaseQuery({ baseUrl: axios.defaults.baseURL }),
    endpoints: () => ({}),
});

import { FunctionComponent, useEffect, useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './nav.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItem } from 'primereact/menuitem';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/chlogo.svg';
import { ROUTES, ROUTE_PARAMS } from '../../constants';
import { unAuthenticate } from '../sign-in/slice';
import { selectCognitoUser, selectIsAuthenticated, selectLogoutStatusSucccedded } from '../sign-in/selector';
import { LOG_OUT_TEXT, PREFERENCES_TEXT, ROUTES_WITHOUT_MENU } from './constants';
import { getCurrentSession, validateSession } from '../../services/auth-service';
import { confirmDialogRedirection } from '../../components/confirm-redirection-dialog.function';

type MenuItemAvailability = {
    menuItem: MenuItem;
    isAvailable: () => boolean;
};

const Nav: FunctionComponent = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const stateIsAuthenticated = useSelector(selectIsAuthenticated);
    const logoutSucccedded = useSelector(selectLogoutStatusSucccedded);
    const currentUser = useSelector(selectCognitoUser);
    const redirectDialogHeaderText = 'Are you sure you want to leave this page?';
    const redirectDialogRescheduleText =
        'Your appointment has not been rescheduled. Are you sure you want to leave this page?';
    const [isAuthenticated, setIsAuthenticated] = useState(stateIsAuthenticated);

    const getUsernameFromUrl = () => {
        const { search } = location;
        const urlParams = new URLSearchParams(search);
        const username = urlParams.get(ROUTE_PARAMS.PATIENT_ID) ?? '';
        return username;
    };

    useEffect(() => {
        async function checkIsAuthenticated() {
            const validatedSession = await validateSession(getUsernameFromUrl());
            setIsAuthenticated(validatedSession.isValid);
        }

        if (!isAuthenticated || !stateIsAuthenticated) {
            checkIsAuthenticated();
        }
    }, []);

    const navigateToPath = (path: string) => {
        history.push(path + location.search);
    };
    const logOutCommand = async () => {
        let currentUsername = currentUser?.Username;
        const hasUsername = currentUsername?.trim().length > 0;
        if (!hasUsername) {
            try {
                const session = await getCurrentSession();
                currentUsername = session.getAccessToken().payload.username;
            } catch (e: unknown) {
                currentUsername = getUsernameFromUrl();
            }
        }
        dispatch(
            unAuthenticate({
                username: currentUsername,
                showLogoutMessage: true,
            }),
        );
        setIsAuthenticated(false);
    };
    const preferencesCommand = () =>
        location.pathname === ROUTES.APPT_SELF_RESCHEDULE
            ? confirmDialogRedirection(redirectDialogHeaderText, redirectDialogRescheduleText, () =>
                  navigateToPath(ROUTES.NOTIFICATION_PREFERENCES),
              )
            : navigateToPath(ROUTES.NOTIFICATION_PREFERENCES);

    const getAvailableMenuItems = (): Array<MenuItem> => {
        const menuItems = new Array<MenuItemAvailability>();

        const existingMenuItems = {
            Preferences: {
                label: PREFERENCES_TEXT,
                command: () => preferencesCommand(),
                className: 'none',
            },
            Logout: {
                label: LOG_OUT_TEXT,
                command: () => logOutCommand(),
                className: 'none',
            },
        };

        menuItems.push({
            menuItem: existingMenuItems.Preferences,
            isAvailable: () => {
                const isUnavailable = ROUTES_WITHOUT_MENU.find(
                    (route) => route.toLocaleLowerCase().trim() === location?.pathname?.toLowerCase().trim(),
                );

                return getUsernameFromUrl()?.trim().length > 0 && !isUnavailable;
            },
        });

        menuItems.push({
            menuItem: existingMenuItems.Logout,
            isAvailable: () => {
                return stateIsAuthenticated || isAuthenticated;
            },
        });

        const menuItemsAvailable = menuItems.filter((mi) => mi.isAvailable()).map((mi) => mi.menuItem);
        return menuItemsAvailable;
    };

    const items: MenuItem[] = getAvailableMenuItems();

    useEffect(() => {
        if (logoutSucccedded) {
            history.push(`${ROUTES.LOGGED_OUT}`);
        }
    }, [logoutSucccedded]);

    const start = () => {
        return (
            <div className="logo-with-spacer">
                <img alt="logo" src={logo} height="40" className="logo" />
            </div>
        );
    };

    const getAppContainerCssClasses = (): string => {
        const classes = ['app-container'];

        if (items.length === 0) {
            classes.push('empty');
        }

        return classes.join(' ');
    };

    return (
        <>
            <div className="top-nav">
                <div data-testid="appContainer" className={getAppContainerCssClasses()}>
                    <Menubar model={items} start={start} />
                </div>
            </div>
            <ConfirmDialog />
        </>
    );
};

export default Nav;

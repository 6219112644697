import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { ROUTES } from './constants';
import { selectCognitoUser } from './containers/sign-in/selector';

export interface ProtectedRouteProps extends RouteProps {
    origin?: string;
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = (props) => {
    const { search, state } = useLocation();
    const cognitoUser = useSelector(selectCognitoUser);
    const { path } = props;
    if (!cognitoUser?.IsAuthenticated) {
        let forwardedState = {
            previousPath: path,
        };
        if (state) {
            forwardedState = {
                ...state,
                ...forwardedState,
            };
        }
        const renderComponent = () => {
            return (
                <Redirect
                    to={{
                        pathname: ROUTES.SIGN_IN,
                        search,
                        state: forwardedState,
                    }}
                />
            );
        };
        return <Route {...props} component={renderComponent} render={undefined} />;
    }
    return <Route {...props} />;
};

export default ProtectedRoute;

import { CommunicationType, parseCommunicationType } from './communication-type';

export type CommunicationMethod = {
    type: CommunicationType;
    value: string;
    id: string;
};

export const parseCommunicationMethods = (jsonCommunicationMethod: string): Array<CommunicationMethod> => {
    if (!jsonCommunicationMethod) return new Array<CommunicationMethod>();

    const parsedCommMethods = JSON.parse(jsonCommunicationMethod);
    const commMethodsArray = parsedCommMethods.map((option: any) => ({
        value: option.value,
        id: option.id,
        type: parseCommunicationType(option.type),
    }));
    return commMethodsArray;
};

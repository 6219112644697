import { FunctionComponent } from 'react';
import { Card } from 'primereact/card';
import './maintenance.scss';

export type MaintenanceProps = {
    message: string;
};

const Maintenance: FunctionComponent<MaintenanceProps> = ({ message }) => {
    return (
        <div className="maintenance-container">
            <Card>{message ?? ''}</Card>
        </div>
    );
};

export default Maintenance;

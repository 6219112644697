/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as SignInReducer } from './containers/sign-in/slice';
import { reducer as AppErrorReducer } from './containers/notification-container/slice';
import { api as graphQLApi } from './services/graphql/concert-base-api';
import { emptyPcApi } from './services/empty-pc-api';
import { reducer as RescheduleRequestReducer } from './containers/appointments/appt-details/slice';

export const staticReducers = {
    signIn: SignInReducer,
    appErrors: AppErrorReducer,
    rescheduleRequest: RescheduleRequestReducer,
    [graphQLApi.reducerPath]: graphQLApi.reducer,
    [emptyPcApi.reducerPath]: emptyPcApi.reducer,
};

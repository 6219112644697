import { ApiCallStatus } from '../../services/api-call-status';
import type { RootState } from '../../store';
import { AuthStep } from '../auth/auth-step';
import { CommunicationMethod } from '../auth/communication-methods/communication-methods';
import { NotificationMessage } from './notification-message';
import { CognitoUserType } from './slice';

export enum CodeValidationState {
    'Failed',
    'Successful',
    'Unknown',
}

export const selectLastError = (state: RootState): any => state.signIn.error;
export const selectCurrentStep = (state: RootState): AuthStep => state.signIn.currentStep;
export const selectCognitoUser = (state: RootState): CognitoUserType => state.signIn.cognitoUser;
export const selectNotificationMessage = (state: RootState): NotificationMessage => state.signIn.notificationMessage;

export const selectAccessToken = (state: RootState): string => state.signIn.accessToken;
export const selectIsAuthenticated = (state: RootState): boolean => state.signIn.isAuthenticated;
export const selectToken = (state: RootState): string => state.signIn.token;

export const selectLogoutStatusSucccedded = (state: RootState): boolean =>
    state.signIn.logoutStatus === ApiCallStatus.succeeded;
export const selectSignInStatusSucccedded = (state: RootState): boolean =>
    state.signIn.signInStatus === ApiCallStatus.succeeded;
export const selectIsAuthenticating = (state: RootState): boolean => state.signIn.isAuthenticating;
export const selectCommunicationMethodSelectionStatusSucccedded = (state: RootState): boolean =>
    state.signIn.communicationMethodSelectionStatus === ApiCallStatus.succeeded;

export const selectCodeValidationState = (state: RootState): CodeValidationState => {
    if (state.signIn.codeValidationStatus === ApiCallStatus.succeeded) return CodeValidationState.Successful;
    if (state.signIn.codeValidationStatus === ApiCallStatus.failed) return CodeValidationState.Failed;
    return CodeValidationState.Unknown;
};

export const selectedCommunicationMethod = (state: RootState): CommunicationMethod =>
    state.signIn.selectedCommunicationMethod;
export const availableCommunicationMethods = (state: RootState): Array<CommunicationMethod> =>
    state.signIn.availableCommunicationMethods;

export const selectValidationCodeLoadingStatusIsLoading = (state: RootState): boolean =>
    state.signIn.validationCodeStatus === ApiCallStatus.loading;
export const selectSignInStatusIsLoading = (state: RootState): boolean =>
    state.signIn.signInStatus === ApiCallStatus.loading;
export const selectCommunicationMethodSelectionStatusIsLoading = (state: RootState): boolean =>
    state.signIn.communicationMethodSelectionStatus === ApiCallStatus.loading;
export const selectCodeValidationStatusIsLoading = (state: RootState): boolean =>
    state.signIn.codeValidationStatus === ApiCallStatus.loading;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../services/api-call-status';
import { AuthStatus } from '../auth/auth-status';
import { AuthStep } from '../auth/auth-step';
import { AuthenticationError } from './authentication-error';
import { CommunicationMethod } from '../auth/communication-methods/communication-methods';
import { NotificationMessage } from './notification-message';

export const initialState = {
    selectedCommunicationMethod: null as CommunicationMethod | null,
    availableCommunicationMethods: new Array<CommunicationMethod>(),
    currentStep: null as AuthStep | null,
    error: null as AuthenticationError | null,
    notificationMessage: null as NotificationMessage | null,

    signInStatus: ApiCallStatus.idle,
    codeValidationStatus: ApiCallStatus.idle,
    communicationMethodSelectionStatus: ApiCallStatus.idle,
    logoutStatus: ApiCallStatus.idle,

    isAuthenticating: false,
    cognitoUser: null as CognitoUserType | null,
    username: null as string | null,
    accessToken: null as string | null,
    isAuthenticated: false,
    status: null as AuthStatus | null,
};

export type CognitoUserPoolDataType = {
    UserPoolId: string;
    ClientId: string;
    endpoint?: string;
    AdvancedSecurityDataCollectionFlag?: boolean;
};
export type CognitoUserType = {
    Username: string;
    Pool: CognitoUserPoolDataType;
    ChallengeParam: ChallengeParamType;
    Session: string;
    AccessToken: string;
    IsAuthenticated: boolean;
};
export type ChallengeParamType = {
    CommunicationOptions: Array<CommunicationMethod>;
};
export type CognitoUserPoolType = {
    UserPoolId: string;
    ClientId: string;
    endpoint?: string | undefined;
    AdvancedSecurityDataCollectionFlag?: boolean | undefined;
};

export type ReinitializeAuthOnCognitoPayload = {
    username: string;
    selectedCommunicationMethod: CommunicationMethod;
    notificationMessage?: NotificationMessage;
};
export type SelectCommunicationMethodOnCognitoPayload = {
    cognitoUser: CognitoUserType;
    communicationMethod: CommunicationMethod;
    source: string;
};
export type ValidateMagicLinkPayload = {
    cognitoUser: CognitoUserType;
    token: string;
};
export type ValidateChallengeCodeOnCognitoPayload = {
    cognitoUser: CognitoUserType;
    code: string;
};
export type NewAuthState = {
    isAuthenticated: boolean;
    token: string;
    cognitoUser: CognitoUserType;
};

export type UnauthenticatPayload = {
    username: string;
    showLogoutMessage: boolean;
};

const authSlice = createSlice({
    name: 'sign-in',
    initialState,
    reducers: {
        resetSignInState: () => initialState,
        storeAvailableCommunicationMethods(state, action: PayloadAction<Array<CommunicationMethod>>) {
            state.availableCommunicationMethods = action.payload;
        },
        storeCommunicationMethod(state, action: PayloadAction<CommunicationMethod>) {
            state.selectedCommunicationMethod = action.payload;
        },
        changeStep(state, action: PayloadAction<AuthStep>) {
            state.currentStep = action.payload as any;
        },
        sendNotification(state, action: PayloadAction<NotificationMessage>) {
            state.notificationMessage = action.payload;
        },
        refreshCognitoUser(state, action: PayloadAction<CognitoUserType>) {
            state.cognitoUser = action.payload;
            state.username = action.payload.Username;
        },

        initiateAuth(state, action: PayloadAction<string>) {
            state.selectedCommunicationMethod = null;
            state.error = null;
            state.cognitoUser = null;
            state.username = action.payload;
            state.signInStatus = ApiCallStatus.loading;
            state.notificationMessage = null;
            state.isAuthenticated = false;
            state.isAuthenticating = false;
        },
        initiateAuthFailure(state, action: PayloadAction<AuthenticationError>) {
            state.username = null;
            state.signInStatus = ApiCallStatus.failed;
        },
        reinitializeAuth(state, action: PayloadAction<ReinitializeAuthOnCognitoPayload>) {
            state.error = null;
            state.cognitoUser = null;
            state.username = action.payload.username;
            state.selectedCommunicationMethod = action.payload.selectedCommunicationMethod;
            state.signInStatus = ApiCallStatus.loading;
            state.notificationMessage = null;
        },
        reinitializeAuthFailure(state, action: PayloadAction<AuthenticationError>) {
            state.signInStatus = ApiCallStatus.failed;
            state.username = null;
        },
        authInitialized(state, action: PayloadAction<CognitoUserType>) {
            state.isAuthenticating = true;
            state.cognitoUser = action.payload;
            state.signInStatus = ApiCallStatus.succeeded;
            state.error = null;
        },

        validateMagicLink(state, action: PayloadAction<ValidateMagicLinkPayload>) {
            state.selectedCommunicationMethod = null;
            state.communicationMethodSelectionStatus = ApiCallStatus.idle;
            state.notificationMessage = null;
        },
        validateMagicLinkSuccess(state, action: PayloadAction<string>) {
            state.codeValidationStatus = ApiCallStatus.succeeded;
            state.accessToken = action.payload;
            state.error = null;
        },
        validateMagicLinkFailure(state) {
            state.codeValidationStatus = ApiCallStatus.failed;
        },

        selectCommunication(state, action: PayloadAction<SelectCommunicationMethodOnCognitoPayload>) {
            state.selectedCommunicationMethod = action.payload.communicationMethod;
            state.communicationMethodSelectionStatus = ApiCallStatus.loading;
            state.notificationMessage = null;
        },
        selectCommunicationFailure(state, action: PayloadAction<AuthenticationError>) {
            state.communicationMethodSelectionStatus = ApiCallStatus.failed;
        },
        communicationMethodSuccess(state, action: PayloadAction<CognitoUserType>) {
            state.cognitoUser = action.payload;
            state.error = null;
            state.communicationMethodSelectionStatus = ApiCallStatus.succeeded;
        },
        validateChallengeCode(state, action: PayloadAction<ValidateChallengeCodeOnCognitoPayload>) {
            state.error = null;
            state.codeValidationStatus = ApiCallStatus.loading;
            state.notificationMessage = null;
        },
        validateChallengeCodeFailure(state, action: PayloadAction<AuthenticationError>) {
            state.codeValidationStatus = ApiCallStatus.failed;
        },
        validateChallengeCodeSuccess(state, action: PayloadAction<string>) {
            state.codeValidationStatus = ApiCallStatus.succeeded;
            state.accessToken = action.payload;
            state.error = null;
        },

        changeAuthState(state, action: PayloadAction<NewAuthState>) {
            state.isAuthenticated = action.payload.isAuthenticated;
            state.isAuthenticating = false;
            state.accessToken = action.payload.token;
            state.cognitoUser = action.payload.cognitoUser;
        },
        unAuthenticate(state, action: PayloadAction<UnauthenticatPayload>) {
            state.isAuthenticating = false;
            state.isAuthenticated = false;
            state.logoutStatus = ApiCallStatus.loading;
            state.notificationMessage = null;
            state.error = null;
        },
        logoutFailure(state, action: PayloadAction<AuthenticationError>) {
            state.logoutStatus = ApiCallStatus.failed;
        },
        logoutSuccess: (state) => ({
            ...state,
            status: AuthStatus.LoggedOut,
            logoutStatus: ApiCallStatus.succeeded,
        }),

        unhandledError(state, action: PayloadAction<AuthenticationError>) {
            state.error = action.payload;
        },
    },
});

export const {
    changeStep,
    resetSignInState,
    storeCommunicationMethod,
    storeAvailableCommunicationMethods,
    refreshCognitoUser,
    sendNotification,

    initiateAuth,
    initiateAuthFailure,
    reinitializeAuth,
    reinitializeAuthFailure,
    authInitialized,
    validateMagicLink,
    validateMagicLinkFailure,
    validateMagicLinkSuccess,
    selectCommunication,
    selectCommunicationFailure,
    communicationMethodSuccess,
    validateChallengeCode,
    validateChallengeCodeFailure,
    validateChallengeCodeSuccess,
    unhandledError,

    changeAuthState,
    unAuthenticate,
    logoutFailure,
    logoutSuccess,
} = authSlice.actions;
export const { reducer } = authSlice;

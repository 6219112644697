import { createContext } from 'react';
import { NotificationFunctionsType } from 'concert-ui-library';
import { NotificationMessage, NotificationMessageType } from './containers/sign-in/notification-message';

export type NotificationState = {
    message: string;
    type: NotificationMessageType;
};

export type NotificaticationContextType = {
    notificationState: NotificationMessage | null;
    setState: (state: NotificationState | null) => void;
};

export const NotificationContext = createContext({} as NotificationFunctionsType);
export const NotificationStateContext = createContext({} as NotificaticationContextType);

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, history } from './store';
import './global';
import { addInterceptors } from './service-client/http-interceptors';
import { getAuthSettings, getConfig } from './config';

addInterceptors(store.dispatch);
getConfig(getAuthSettings());

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <App history={history} />
        </Provider>
    </StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    appError: null,
};

const errorSlice = createSlice({
    name: 'appError',
    initialState,
    reducers: {
        addError(state, action) {
            state.appError = action.payload;
        },
    },
});

export const { addError } = errorSlice.actions;

export const { reducer } = errorSlice;

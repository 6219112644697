export enum CommunicationType {
    Mobile = 'mobile',
    Phone = 'phone',
    Email = 'email',
    MagicLink = 'magic-link',
}

export const DEFAULT_COMMUNICATION_TYPE = CommunicationType.Email;

export const parseCommunicationType = (communicationType: string): CommunicationType => {
    if (!communicationType || communicationType.trim().length <= 0) return DEFAULT_COMMUNICATION_TYPE;

    const communicationTypeTrimmed = communicationType.trim().toLowerCase();

    return (Object.values(CommunicationType) as unknown as string[]).includes(communicationTypeTrimmed)
        ? (communicationTypeTrimmed as CommunicationType)
        : DEFAULT_COMMUNICATION_TYPE;
};

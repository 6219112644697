import { ROUTES } from '../../constants';
import { AuthenticationError } from './authentication-error';

export const INVALID_CODE_ERROR: AuthenticationError = {
    message: 'InvalidCode',
    name: 'InvalidCode',
    customMessage: 'Incorrect or expired login code. Please try again.',
};

export const SIGNIN_ERRORS = {
    NO_CURRENT_USER: 'No current user',
    NOT_AUTHORIZED: {
        NAME: 'NotAuthorizedException',
        EXPIRED_SESSION: {
            MESSAGE: 'Invalid session for the user.',
        },
        INVALID_SESSION: {
            MESSAGE: 'Incorrect username or password.',
        },
    },
};

export const REDIRECTION_MESSAGES = {
    [ROUTES.APPT_SELF_RESCHEDULE]: 'to continue rescheduling your appointment',
    [ROUTES.NOTIFICATION_PREFERENCES]: 'to manage your preferences',
    [ROUTES.RESOURCES]: 'to access your resources',
    [ROUTES.MESSAGES]: 'to access your messages',
    [ROUTES.CONVERSATION]: 'to access your messages',
};

import { datadogRum } from '@datadog/browser-rum';
import { FunctionComponent, useEffect } from 'react';
import { DatadogSettings, getDatadogConfig } from '../../config';

const Rum: FunctionComponent = () => {
    useEffect(() => {
        const datadogConfig: DatadogSettings = getDatadogConfig();
        datadogRum.init({
            applicationId: datadogConfig.applicationId,
            clientToken: datadogConfig.clientToken,
            site: datadogConfig.site,
            service: datadogConfig.service,
            // Specify a version number to identify the deployed version of your application in Datadog
            version: datadogConfig.version,
            sampleRate: datadogConfig.sampleRate,
            premiumSampleRate: datadogConfig.premiumSampleRate,
            trackInteractions: datadogConfig.trackInteractions,
        });
    }, []);
    return null;
};

export default Rum;

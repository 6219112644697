import { ROUTES } from '../../constants';

export const LOG_OUT_TEXT = 'Log out';
export const PREFERENCES_TEXT = 'Preferences';

export const ROUTES_WITH_MENU: Array<string> = [
    ROUTES.APPT_DETAILS,
    ROUTES.APPT_SELF_RESCHEDULE,
    ROUTES.SIGN_IN,
    ROUTES.NOTIFICATION_PREFERENCES,
    ROUTES.AUTHENTICATED,
    ROUTES.UNSUBSCRIBE_APPT_CONFIRMATION,
    ROUTES.UNSUBSCRIBE_SAT_SURVEY,
    ROUTES.QUESTIONNAIRES,
];
export const ROUTES_WITHOUT_MENU: Array<string> = [
    ROUTES.SURVEY,
    ROUTES.SURVEY_COMPLETED,
    ROUTES.LOGGED_OUT,
    ROUTES.SIGN_IN_ERROR,
];

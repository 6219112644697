import { ApiCallStatus } from '../../services/api-call-status';
import { RootState } from '../../store';
import { PatientContext } from './slice';

export const selectPatientContextIsLoading = (state: RootState): boolean => {
    return state.patientContext.getNotificationStatus === ApiCallStatus.loading;
};

export const selectPatientContextFailedToLoad = (state: RootState): boolean => {
    return state.patientContext.getNotificationStatus === ApiCallStatus.failed;
};

export const selectPatientContextError = (state: RootState): { customMessage: string } => {
    return state.patientContext.error;
};

export const selectPatientContextData = (state: RootState): PatientContext => {
    return state.patientContext?.data;
};

export const selectApptNotEmailOptOut = (state: RootState): boolean => {
    return state.patientContext.appointmentNotificationsEmailOptOut;
};
export const selectApptNotSMSOptOut = (state: RootState): boolean => {
    return state.patientContext.appointmentNotificationsSMSOptOut;
};
export const selectSurveyEmailOptOut = (state: RootState): boolean => {
    return state.patientContext.satisfactionSurveyEmailOptOut;
};
export const selectSurveySMSOptOut = (state: RootState): boolean => {
    return state.patientContext.satisfactionSurveySMSOptOut;
};
export const selectMessagesAndResourcesEmailOptOut = (state: RootState): boolean => {
    return state.patientContext.messagesAndResourcesEmailOptOut;
};
export const selectMessagesAndResourcesSMSOptOut = (state: RootState): boolean => {
    return state.patientContext.messagesAndResourcesSMSOptOut;
};

export const selectUpdateSelectApptNotEmailOptOutIsLoading = (state: RootState): boolean => {
    return state.patientContext.apptNotEmailOptOutUpdateStatus === ApiCallStatus.loading;
};
export const selectUpdateSelectApptNotSMSOptOutIsLoading = (state: RootState): boolean => {
    return state.patientContext.apptNotSMSOptOutUpdateStatus === ApiCallStatus.loading;
};
export const selectUpdateSelectSurveyEmailOptOutIsLoading = (state: RootState): boolean => {
    return state.patientContext.surveyEmailOptOutUpdateStatus === ApiCallStatus.loading;
};
export const selectUpdateSelectSurveySMSOptOutIsLoading = (state: RootState): boolean => {
    return state.patientContext.surveySMSOptOutUpdateStatus === ApiCallStatus.loading;
};
export const selectUpdateResourcesEmailOptOutIsLoading = (state: RootState): boolean => {
    return state.patientContext.resourcesEmailOptOutUpdateStatus === ApiCallStatus.loading;
};
export const selectUpdateMessagesAndResourcesEmailOptOutIsLoading = (state: RootState): boolean => {
    return state.patientContext.messagesAndResourcesEmailOptOutUpdateStatus === ApiCallStatus.loading;
};
export const selectUpdateMessagesAndResourcesSMSOptOutIsLoading = (state: RootState): boolean => {
    return state.patientContext.messagesAndResourcesSMSOptOutUpdateStatus === ApiCallStatus.loading;
};

export const selectUpdateSelectApptNotEmailOptOutFailed = (state: RootState): boolean => {
    return state.patientContext.apptNotEmailOptOutUpdateStatus === ApiCallStatus.failed;
};
export const selectUpdateSelectApptNotSMSOptOutFailed = (state: RootState): boolean => {
    return state.patientContext.apptNotSMSOptOutUpdateStatus === ApiCallStatus.failed;
};
export const selectUpdateSelectSurveyEmailOptOutFailed = (state: RootState): boolean => {
    return state.patientContext.surveyEmailOptOutUpdateStatus === ApiCallStatus.failed;
};
export const selectUpdateSelectSurveySMSOptOutFailed = (state: RootState): boolean => {
    return state.patientContext.surveySMSOptOutUpdateStatus === ApiCallStatus.failed;
};
export const selectUpdateResourcesEmailOptOutFailed = (state: RootState): boolean => {
    return state.patientContext.resourcesEmailOptOutUpdateStatus === ApiCallStatus.failed;
};
export const selectUpdateEmailUnsubscribeStatusIsLoading = (state: RootState): boolean => {
    return state.patientContext.emailUnsubscribeUpdateStatus === ApiCallStatus.loading;
};
export const selectUpdateMessagesAndResourcesEmailOptOutFailed = (state: RootState): boolean => {
    return state.patientContext.messagesAndResourcesEmailOptOutUpdateStatus === ApiCallStatus.failed;
};
export const selectUpdateMessagesAndResourcesSMSOptOutFailed = (state: RootState): boolean => {
    return state.patientContext.messagesAndResourcesSMSOptOutUpdateStatus === ApiCallStatus.failed;
};

export const selectUpdatePreferencesStatus = (state: RootState): ApiCallStatus => {
    return state.patientContext.updateNotificationPreferencesStatus;
};

import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../../services/api-call-status';
import { AvailabilitySettings } from '../../../services/graphql/generated';

export enum RescheduleRequestSteps {
    Preferences,
    Submission,
}

export const initialState = {
    error: null,
    updateAvailabilityStatus: ApiCallStatus.idle,
    getAvailabilityStatus: ApiCallStatus.idle,
    rescheduleRequestStatus: ApiCallStatus.idle,
    availabilitySettings: {} as AvailabilitySettings,
    currentStep: RescheduleRequestSteps.Preferences,
    saveSettings: false,
};

const rescheduleRequestSlice = createSlice({
    name: 'rescheduleRequestSteps',
    initialState,
    reducers: {
        changeStep(state, action) {
            state.currentStep = action.payload;
            state.saveSettings = false;
        },

        cancelUpdateAvailabilitySettings(state) {
            state.updateAvailabilityStatus = ApiCallStatus.idle;
        },
        updateAvailabilitySettings(state) {
            state.updateAvailabilityStatus = ApiCallStatus.loading;
        },
        updateAvailabilitySettingsSuccess(state, action: AnyAction) {
            state.updateAvailabilityStatus = ApiCallStatus.succeeded;
            state.availabilitySettings = action.payload;
        },
        updateAvailabilitySettingstFailure(state, action) {
            state.updateAvailabilityStatus = ApiCallStatus.failed;
            state.error = action.payload?.error;
        },

        getAvailabilitySettings(state) {
            state.getAvailabilityStatus = ApiCallStatus.loading;
        },
        getAvailabilitySettingsSuccess(state, action) {
            state.availabilitySettings = action.payload;
            state.getAvailabilityStatus = ApiCallStatus.succeeded;
        },
        getAvailabilitySettingsFailure(state, action) {
            state.getAvailabilityStatus = ApiCallStatus.failed;
            state.error = action.payload?.error;
        },

        rescheduleRequest(state) {
            state.rescheduleRequestStatus = ApiCallStatus.loading;
        },
        rescheduleRequestSuccess(state) {
            state.rescheduleRequestStatus = ApiCallStatus.succeeded;
        },
        rescheduleRequestFailure(state, action) {
            state.rescheduleRequestStatus = ApiCallStatus.failed;
            state.error = action.payload?.error;
        },

        updateAvailabilitySettingsAndChangeStep(state, action) {
            state.currentStep = action.payload.nextStep;
            state.availabilitySettings = action.payload.availabilitySettings;
        },
    },
});

export const {
    changeStep,
    cancelUpdateAvailabilitySettings,
    updateAvailabilitySettings,
    updateAvailabilitySettingsSuccess,
    updateAvailabilitySettingstFailure,
    getAvailabilitySettings,
    getAvailabilitySettingsSuccess,
    getAvailabilitySettingsFailure,
    rescheduleRequest,
    rescheduleRequestSuccess,
    rescheduleRequestFailure,
    updateAvailabilitySettingsAndChangeStep,
} = rescheduleRequestSlice.actions;

export const { reducer } = rescheduleRequestSlice;

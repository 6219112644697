import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NotificationContext, NotificationStateContext } from '../../notification-context';
import { selectAppError } from './selector';
import { useShowNotificationMessage } from '../../use-show-notification-message';

export const GlobalNotificationComponent: FC = () => {
    const appError = useSelector(selectAppError);
    const notificationContext = useContext(NotificationContext);
    const { notificationState } = useContext(NotificationStateContext);
    useEffect(() => {
        if (appError === null) {
            return;
        }
        (notificationContext as any).showError((appError as any).customMessage);
    }, [appError]);

    useShowNotificationMessage(notificationState);
    return <div />;
};

import { APPOINTMENT_ERROR_MESSAGES, API_RESCHEDULE_ERROR_MESSAGES } from './appt-details/constants';

export const appointmentAvailabilityErrorMessageResolver = (
    errorMessage: string,
    defaultMessage: string = APPOINTMENT_ERROR_MESSAGES.UNABLE_TO_COMPLETE_SELF_RESCHEDULE,
): string => {
    const isUnableToReschedule =
        errorMessage?.includes(API_RESCHEDULE_ERROR_MESSAGES.APPOINTMENT_INACTIVE_USER) ||
        errorMessage?.includes(API_RESCHEDULE_ERROR_MESSAGES.APPOINTMENT_COMPLETED_VISIT);

    if (isUnableToReschedule) {
        return APPOINTMENT_ERROR_MESSAGES.UNABLE_TO_RESCHEDULE_APPOINTMENT;
    }
    if (errorMessage?.includes(API_RESCHEDULE_ERROR_MESSAGES.APPOINTMENT_ALREADY_EXISTS_IN_SLOT)) {
        return APPOINTMENT_ERROR_MESSAGES.UNABLE_TO_SELF_RESCHEDULE_SLOT;
    }
    return defaultMessage;
};

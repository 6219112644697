import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { SIGNIN_ERRORS } from '../containers/sign-in/constants';
import { CommunicationType } from '../containers/auth/communication-methods/communication-type';

type CommunicationMethodResponseType = {
    medium: string;
    source: string;
};

export const MagicLinkCommunicationMethod = {
    medium: CommunicationType.MagicLink,
    source: CommunicationType.MagicLink,
};

export const getCurrentSession = async (): Promise<CognitoUserSession> => Auth.currentSession();

export const signIn = async (userName: string): Promise<any> => Auth.signIn(userName);

export const sendCustomChallengeAnswer = async (
    user: CognitoUser,
    code: string,
    clientMetadata?: CommunicationMethodResponseType,
): Promise<any> => Auth.sendCustomChallengeAnswer(user, code, clientMetadata);

export const selectCommunicationMethod = async (
    user: CognitoUser,
    password: string,
    communicationMethod: CommunicationMethodResponseType,
): Promise<any> =>
    Auth.sendCustomChallengeAnswer(user, password, {
        medium: communicationMethod.medium,
        source: communicationMethod.source,
    });

export const signOut = (): Promise<void> => Auth.signOut();
export const getCurrentAuthenticatedUser = (): Promise<any> => Auth.currentAuthenticatedUser();

export type ValidateSessionResult = {
    isValid: boolean;
    session: CognitoUserSession | null;
    error: unknown | null;
};

export const validateSession = async (username: string): Promise<ValidateSessionResult> => {
    const result: ValidateSessionResult = {
        isValid: false,
        session: null,
        error: null,
    };

    try {
        const session = await getCurrentSession();
        result.session = session;
        result.isValid = session.isValid() && session.getAccessToken().payload.username === username;
    } catch (e: unknown) {
        result.isValid = false;
        if (isInvalidSessionResponse(e)) {
            return result;
        }
        result.error = e;
    }

    return result;
};

const isInvalidSessionResponse = (error: unknown) => {
    if (typeof error === 'string') {
        return error === SIGNIN_ERRORS.NO_CURRENT_USER;
    }
    if (error instanceof Error) {
        return error.name === SIGNIN_ERRORS.NOT_AUTHORIZED.NAME;
    }
    return false;
};

import { confirmDialog } from 'primereact/confirmdialog';

export const confirmDialogRedirection = (header: string, message: string, acceptFunc: () => void): void => {
    confirmDialog({
        header,
        message,
        className: 'redirection-confirm-dialog',
        accept: () => acceptFunc(),
    });
};

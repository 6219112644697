import { FunctionComponent, useMemo, useState } from 'react';
import { NotificationState, NotificaticationContextType, NotificationStateContext } from './notification-context';

const NotificationProvider: FunctionComponent = ({ children }) => {
    const [notificationState, setNotificationState] = useState<NotificationState | null>(null);
    const messageState = useMemo<NotificaticationContextType>(
        () => ({
            notificationState,
            setState: setNotificationState,
        }),
        [notificationState],
    );
    return <NotificationStateContext.Provider value={messageState}>{children}</NotificationStateContext.Provider>;
};

export default NotificationProvider;

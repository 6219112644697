import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { QueryReturnValue } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { appointmentAvailabilityErrorMessageResolver } from '../containers/appointments/appointment-error-message-resolver';
import { APPOINTMENT_ERROR_MESSAGES } from '../containers/appointments/appt-details/constants';
import { ReminderStatus } from '../containers/appointments/appt-details/reminder-status';
import { Appointment } from '../containers/appointments/appt-details/appt-types';
import { initializeAxiosConfigDefaults } from '../service-client/http-interceptors';
import type { RequestReschedulePayload } from './graphql/concert-base-api';
import { ApiCallStatus } from './api-call-status';
import { HttpResponseCodes } from '../enums';
import { VisitStatus } from '../containers/appointments/appt-details/visit-status';
import { emptyPcApi } from './empty-pc-api';
import { ApiError } from './api-types';

initializeAxiosConfigDefaults();

const excludedHttpErrors = [HttpResponseCodes.NOT_FOUND, HttpResponseCodes.BAD_REQUEST, HttpResponseCodes.CONFLICT];

const buildGetAppointmentFromAPIEndpoint = (
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        never,
        string
    >,
) => {
    const getAppointmentSuccess = (payload: Appointment) => {
        payload.calendarEvent = {
            ...payload.calendarEvent,
            startDateTime: payload.calendarEvent.startDateTime,
            endDateTime: payload.calendarEvent.endDateTime,
            clinicianFirstName: payload.calendarEvent.clinicianName?.split(' ')[0],
        };
        return {
            data: payload,
        };
    };
    const getAppointmentFailure = (payload: ApiError | null) => ({
        error: payload,
    });

    const errorHandler = (error: any): QueryReturnValue<Appointment, FetchBaseQueryError, unknown> => {
        error.customMessage = null;
        const apiError = !excludedHttpErrors.includes(error.status as HttpResponseCodes)
            ? ({
                  displayMessage: APPOINTMENT_ERROR_MESSAGES.UNABLE_TO_LOAD,
                  message: error.data?.message,
                  status: error.status,
              } as ApiError)
            : null;
        const getAppointmentFailureError = getAppointmentFailure(apiError);
        return {
            error: {
                error: getAppointmentFailureError.error?.displayMessage || '',
                status: 'CUSTOM_ERROR',
                data: getAppointmentFailureError.error,
            },
        };
    };

    return builder.query<Appointment, string>({
        queryFn: async (eventId, _queryApi, _extraOptions, fetchWithBQ) => {
            try {
                const result = await fetchWithBQ(`appointment?eventId=${eventId}`);
                if (result?.error) return errorHandler(result.error);
                return getAppointmentSuccess(result.data as Appointment);
            } catch (error) {
                return errorHandler(error);
            }
        },
    });
};

const buildConfirmAppointmentFromAPIEndpoint = (
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        never,
        string
    >,
) => {
    const confirmAppointmentSuccess = () => {
        const state = {
            confirmStatus: ApiCallStatus.succeeded,
            confirmationError: null,
        };
        return state;
    };
    const confirmAppointmentFailure = (payload: ApiError | null) => ({
        confirmStatus: ApiCallStatus.failed,
        confirmationError: payload,
    });

    const errorHandler = (error: any): QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> => {
        const apiError: ApiError = {
            displayMessage: APPOINTMENT_ERROR_MESSAGES.UNABLE_TO_CONFIRM,
            message: error.message,
            status: error.status,
        };
        const confirmationException = confirmAppointmentFailure(apiError);
        return {
            error: {
                error: confirmationException.confirmationError?.displayMessage || '',
                status: 'CUSTOM_ERROR',
                data: confirmationException.confirmationError,
            },
        };
    };

    return builder.mutation<unknown, string>({
        queryFn: async (
            eventId,
            _queryApi,
            _extraOptions,
            fetchWithBQ,
        ): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> => {
            try {
                const result = await fetchWithBQ({
                    url: `/appointment/confirm?eventId=${eventId}`,
                    method: 'POST',
                    headers: { 'Content-type': 'application/json' },
                });
                if (result.error) return errorHandler(result.error);
                return {
                    data: confirmAppointmentSuccess(),
                };
            } catch (error) {
                return errorHandler(error);
            }
        },
        async onQueryStarted(eventId, { dispatch, queryFulfilled }) {
            let patchResult: PatchCollection | null = null;
            try {
                await queryFulfilled;
                patchResult = dispatch(
                    apptDetailsApi.util.updateQueryData('getAppointmentFromAPI', eventId, (draft) => {
                        const eventWithReminderStatusUpdated = {
                            ...draft,
                            calendarEvent: { ...draft.calendarEvent, reminderStatus: ReminderStatus.CONFIRMED },
                        };
                        return eventWithReminderStatusUpdated;
                    }),
                );
            } catch (e) {
                patchResult?.undo();
            }
        },
    });
};

const buildRequestRescheduleAppointmentFromAPI = (
    builder: EndpointBuilder<
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
        never,
        string
    >,
) => {
    const requestRescheduleAppointmentSuccess = () => {
        const state = {
            requestRescheduleStatus: ApiCallStatus.succeeded,
            requestRescheduleError: null,
        };
        return state;
    };
    const requestRescheduleAppointmentFailure = (payload: ApiError | null) => ({
        requestRescheduleStatus: ApiCallStatus.failed,
        requestRescheduleError: payload,
    });

    const errorHandler = (error: any): QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> => {
        const displayMessage = appointmentAvailabilityErrorMessageResolver(
            error.data?.message ?? error.message,
            APPOINTMENT_ERROR_MESSAGES.UNABLE_TO_REQUEST_RESCHEDULE,
        );
        const apiError: ApiError = {
            displayMessage,
            message: error.message,
            status: error.status,
        };
        const requestRescheduleAppointmentFailureError = requestRescheduleAppointmentFailure(apiError);
        return {
            error: {
                error: requestRescheduleAppointmentFailureError.requestRescheduleError?.displayMessage || '',
                status: 'CUSTOM_ERROR',
                data: requestRescheduleAppointmentFailureError.requestRescheduleError,
            },
        };
    };

    return builder.mutation<unknown, RequestReschedulePayload>({
        async queryFn(
            body,
            _queryApi,
            _extraOptions,
            fetchWithBQ,
        ): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> {
            try {
                const result = await fetchWithBQ({
                    url: `/appointment/request-reschedule?eventId=${body.id}`,
                    method: 'POST',
                    body,
                    headers: { 'Content-type': 'application/json' },
                });
                if (result.error) return errorHandler(result.error);
                return {
                    data: requestRescheduleAppointmentSuccess(),
                };
            } catch (error) {
                return errorHandler(error);
            }
        },
        async onQueryStarted(payload, { dispatch, queryFulfilled }) {
            let patchResult: PatchCollection | null = null;
            try {
                await queryFulfilled;
                patchResult = dispatch(
                    apptDetailsApi.util.updateQueryData('getAppointmentFromAPI', payload.id, (draft) => {
                        const eventWithReminderStatusUpdated = {
                            ...draft,
                            calendarEvent: {
                                ...draft.calendarEvent,
                                reminderStatus: ReminderStatus.PATIENT_CANCELLED,
                            },
                        };
                        return eventWithReminderStatusUpdated;
                    }),
                );
            } catch (e: any) {
                patchResult?.undo();
                if (e.error.data.displayMessage === APPOINTMENT_ERROR_MESSAGES.UNABLE_TO_RESCHEDULE_APPOINTMENT) {
                    patchResult = dispatch(updateCalendarEventCompleteStatusQueryData(payload.id));
                }
            }
        },
    });
};

export const updateCalendarEventCompleteStatusQueryData = (appointmentId: string) => {
    return apptDetailsApi.util.updateQueryData('getAppointmentFromAPI', appointmentId, (draft) => {
        const eventWithVisitStatusUpdated = {
            ...draft,
            calendarEvent: {
                ...draft.calendarEvent,
                visitStatus: VisitStatus.COMPLETED_VISIT,
            },
        };
        return eventWithVisitStatusUpdated;
    });
};
export const apptDetailsApi = emptyPcApi.injectEndpoints({
    endpoints: (builder) => ({
        getAppointmentFromAPI: buildGetAppointmentFromAPIEndpoint(builder),
        confirmAppointmentFromAPI: buildConfirmAppointmentFromAPIEndpoint(builder),
        requestRescheduleAppointmentFromAPI: buildRequestRescheduleAppointmentFromAPI(builder),
    }),
});

export const {
    useGetAppointmentFromAPIQuery,
    useLazyGetAppointmentFromAPIQuery,
    useConfirmAppointmentFromAPIMutation,
    useRequestRescheduleAppointmentFromAPIMutation,
} = apptDetailsApi;

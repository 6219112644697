export enum NotificationMessageType {
    Info,
    Success,
    Warning,
    Error,
}
export type NotificationMessage = {
    message: string;
    type: NotificationMessageType;
};

export const SessionExpiredNotificationMessage: NotificationMessage = {
    message: `We've sent you a new login code. Note that each code expires after 3 minutes.`,
    type: NotificationMessageType.Warning,
};

export const LoggedOutNotificationMessage: NotificationMessage = {
    message: `You've been logged out.`,
    type: NotificationMessageType.Warning,
};

import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import type { AppDispatch } from '../store';

export const DEFAULT_PC_API_URL = 'https://localhost:45392';
export const addInterceptors = (dispatch: AppDispatch): void => {
    axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
            // const { origin } = new URL(config.url ? config.url : '');
            // const allowedOrigins = [axios.defaults.baseURL];
            // const token = sessionStorage.getItem('chToken');
            // if (allowedOrigins.includes(origin)) {
            //     config.headers.authorization = `Bearer ${token}`;
            // }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            const status = error.response?.status;
            if (status === 401) {
                // TODO: Do something here to unauthenticate
            }
            return Promise.reject(error);
        },
    );
};

export const initializeAxiosConfigDefaults = (): void => {
    axios.defaults.baseURL = window.config?.apiUrl ?? DEFAULT_PC_API_URL;
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { Route, RouteProps } from 'react-router-dom';
import { FC } from 'react';
import Maintenance from './containers/maintenance/maintenance';
import * as PR from './protected-route';
import { maintenanceService } from './services/maintenance-service';

interface MaintenanceObservingRouteProps extends RouteProps {
    protected?: boolean;
}

export const MaintenanceObservingRoute: FC<MaintenanceObservingRouteProps> = (props) => {
    const { isUnderMaintenance, maintenanceMessage } = maintenanceService();

    if (isUnderMaintenance()) {
        return (
            <Route {...props}>
                <Maintenance message={maintenanceMessage} />
            </Route>
        );
    }
    if (props.protected) {
        return <PR.ProtectedRoute {...props} />;
    }
    return <Route {...props} />;
};

import { getAppSettings } from '../../../config';
import { AppointmentStatus } from './appointment-status';
import { ReminderStatus } from './reminder-status';

export const appointmentMessagesByStatus: Record<AppointmentStatus, string> = {
    [AppointmentStatus.APPOINTMENT_PASSED]: 'Appointment passed',
    [AppointmentStatus.APPOINTMENT_SCHEDULED]: 'Appointment scheduled',
    [AppointmentStatus.APPOINTMENT_CONFIRM]: 'Please confirm your upcoming appointment',
    [AppointmentStatus.APPOINTMENT_NOT_FOUND]: 'Appointment not found',
};

export const APPOINTMENT_REQUEST_SELF_RESCHEDULE = 'Please reschedule at your earliest convenience';
export const APPOINTMENT_COMPLETE_QUESTIONNAIRES = 'Please complete the questionnaires requested by your care team';

export const reminderMessagesByStatus: Record<ReminderStatus, string> = {
    [ReminderStatus.CONFIRMED]: 'Appointment confirmed',
    [ReminderStatus.NOT_APPLICABLE]: '',
    [ReminderStatus.PATIENT_CANCELLED]: 'Appointment reschedule requested',
    [ReminderStatus.RESCHEDULED]: 'Appointment not found',
    [ReminderStatus.REMINDED]: '',
};

export const EVENT_TYPE = {
    VIDEO: 'Video Meeting',
    PHONE: 'Phone Call',
    IN_PERSON: 'In-Person Session',
} as const;

export const APPOINTMENT_ERROR_MESSAGES = {
    UNABLE_TO_CONFIRM: 'Unable to confirm your appointment at this time, please refresh or try again later.',
    UNABLE_TO_LOAD: 'Unable to load the appointment. Please refresh the page to try again.',
    UNABLE_TO_REQUEST_RESCHEDULE:
        'Unable to complete your reschedule request at this time, please refresh or try again later.',
    UNABLE_TO_COMPLETE_SELF_RESCHEDULE: `Unable to reschedule your appointment at this time, please try again later or call ${
        getAppSettings().supportPhoneNumber
    } for scheduling assistance.`,
    UNABLE_TO_SELF_RESCHEDULE_SLOT:
        "The time you've selected is no longer available. Please choose from remaining available times or select another day.",
    UNABLE_TO_RESCHEDULE_APPOINTMENT: `Unable to reschedule your appointment at this time, please contact ${
        getAppSettings().supportPhoneNumber
    } for scheduling assistance.`,
};

export const APPOINTMENT_SUCCESS_MESSAGES = {
    SUCCESS_CONFIRM: 'Appointment successfully confirmed!',
    SUCCESS_REQUEST_RESCHEDULE: 'Appointment reschedule requested.',
    SUCCESS_SELF_RESCHEDULE: 'Appointment successfully rescheduled.',
};

export const RESCHEDULE_ERROR_MESSAGES = {
    COULD_NOT_LOAD_AVAILABILITY: 'Could not load the availability for the selected date.',
    COULD_NOT_LOAD_AVAILABILITY_WINDOW: 'Could not load the availability for this appointment.',
    COULD_NOT_LOAD_AVAILABILITY_SETTINGS: 'Unable to show your Preferences at this time, please try again later.',
    COULD_NOT_SAVE_AVAILABILITY_SETTINGS: 'Unable to update your Preferences at this time, please try again later.',
};

export const API_RESCHEDULE_ERROR_MESSAGES = {
    APPOINTMENT_COMPLETED_VISIT: 'has completed visit status.',
    APPOINTMENT_ALREADY_EXISTS_IN_SLOT: 'There is another appointment on this slot, please choose another slot',
    APPOINTMENT_INACTIVE_USER: 'has an inactive owner.',
};

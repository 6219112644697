import { useEffect, useContext } from 'react';
import { NotificationMessage, NotificationMessageType } from './containers/sign-in/notification-message';
import { NotificationContext } from './notification-context';

export function useShowNotificationMessage(notificationMessage: NotificationMessage | null): void {
    const notificationContext = useContext(NotificationContext);
    useEffect(() => {
        if (!notificationMessage) {
            return;
        }
        switch (notificationMessage.type) {
            case NotificationMessageType.Error:
                notificationContext.showError(notificationMessage.message);
                break;
            case NotificationMessageType.Info:
                notificationContext.showInfo(notificationMessage.message);
                break;
            case NotificationMessageType.Warning:
                notificationContext.showWarning(notificationMessage.message);
                break;
            case NotificationMessageType.Success:
                notificationContext.showSuccess(notificationMessage.message);
                break;
            default:
                break;
        }
    }, [notificationMessage]);
}

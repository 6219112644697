import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery } from 'redux-saga/effects';
import {
    MagicLinkCommunicationMethod,
    selectCommunicationMethod,
    sendCustomChallengeAnswer,
    signIn,
    signOut,
} from '../../services/auth-service';
import {
    initiateAuth,
    reinitializeAuth,
    authInitialized,
    selectCommunication,
    communicationMethodSuccess,
    initiateAuthFailure,
    reinitializeAuthFailure,
    ReinitializeAuthOnCognitoPayload,
    selectCommunicationFailure,
    SelectCommunicationMethodOnCognitoPayload,
    validateChallengeCode,
    ValidateChallengeCodeOnCognitoPayload,
    validateChallengeCodeSuccess,
    changeAuthState,
    logoutFailure,
    unAuthenticate,
    validateChallengeCodeFailure,
    CognitoUserType,
    refreshCognitoUser,
    unhandledError,
    sendNotification,
    resetSignInState,
    UnauthenticatPayload,
    logoutSuccess,
    validateMagicLink,
    ValidateMagicLinkPayload,
    validateMagicLinkFailure,
    validateMagicLinkSuccess,
} from './slice';
import { CommunicationMethod } from '../auth/communication-methods/communication-methods';
import { AuthenticationError } from './authentication-error';
import { SIGNIN_ERRORS, INVALID_CODE_ERROR } from './constants';
import { selectCognitoUser, selectedCommunicationMethod } from './selector';
import { fromCognitoUser, toCognitoUser } from './cognito-user-mapper';
import { toAuthenticationError } from './authentication-error-mapper';
import { LoggedOutNotificationMessage, SessionExpiredNotificationMessage } from './notification-message';

const INITIATE_AUTH_ERROR = `Unable to initiate the authentication process. Please refresh the page to try again.`;
const REINITIALIZE_AUTH_ERROR = `Unable to initiate the authentication process. Please refresh the page to try again.`;
const SELECT_COMMUNICATION_ERROR = `Unable to send the code at this time, please try again later.`;
const VALIDATE_CHALLENGE_ERROR = `Unable to validate the validation code specified at this time, please try again later.`;
const LOGOUT_ERROR = `Unable to logout at this time, please try again later.`;

const isAuthorizationError = (error: any) => error?.name === SIGNIN_ERRORS.NOT_AUTHORIZED.NAME;
const isSessionExpiredError = (error: any) =>
    isAuthorizationError(error) && error?.message === SIGNIN_ERRORS.NOT_AUTHORIZED.EXPIRED_SESSION.MESSAGE;
const isSessionInvalidError = (error: any) =>
    isAuthorizationError(error) && error?.message === SIGNIN_ERRORS.NOT_AUTHORIZED.INVALID_SESSION.MESSAGE;

export function* initiateAuthOnCognito(action: PayloadAction<string>): Generator<unknown, void, unknown> {
    try {
        const cognitoUser: any = yield signIn(action.payload);
        yield put(authInitialized(fromCognitoUser(cognitoUser)));
    } catch (error: any) {
        yield put(initiateAuthFailure(toAuthenticationError(error, INITIATE_AUTH_ERROR)));
    }
}

export function* reinitializeAuthOnCognito(
    action: PayloadAction<ReinitializeAuthOnCognitoPayload>,
): Generator<unknown, void, unknown> {
    try {
        const { username, selectedCommunicationMethod: communicationMethod } = action.payload;
        const cognitoUser = fromCognitoUser(yield signIn(username));

        yield put(initiateAuth(username));

        if (communicationMethod) {
            yield put(
                selectCommunication({
                    cognitoUser,
                    communicationMethod,
                    source: '',
                }),
            );

            const { notificationMessage } = action.payload;
            if (notificationMessage) yield put(sendNotification(notificationMessage));

            yield put(authInitialized(cognitoUser));
        }
    } catch (error: any) {
        yield put(reinitializeAuthFailure(toAuthenticationError(error, REINITIALIZE_AUTH_ERROR)));
    }
}

export function* validateMagicLinkOnCognito(
    action: PayloadAction<ValidateMagicLinkPayload>,
): Generator<unknown, void, unknown> {
    try {
        const cognitoUser = toCognitoUser(action.payload.cognitoUser);
        yield selectCommunicationMethod(cognitoUser, action.payload.token, MagicLinkCommunicationMethod);

        const response = yield sendCustomChallengeAnswer(
            cognitoUser,
            action.payload.token,
            MagicLinkCommunicationMethod,
        );

        const user = fromCognitoUser(response);

        if (user.IsAuthenticated) {
            yield put(validateMagicLinkSuccess(user.AccessToken));
        } else {
            yield put(validateMagicLinkFailure());
        }
    } catch (ex) {
        yield put(validateMagicLinkFailure());
    }
}

export function* selectCommunicationMethodOnCognito(
    action: PayloadAction<SelectCommunicationMethodOnCognitoPayload>,
): Generator<unknown, void, unknown> {
    try {
        const cognitoUser = toCognitoUser(action.payload.cognitoUser);
        const newSession: any = yield selectCommunicationMethod(cognitoUser, '__dummy__', {
            medium: action.payload.communicationMethod?.id as string,
            source: action.payload.source as string,
        });

        yield put(
            communicationMethodSuccess({
                ...fromCognitoUser(newSession),
                ChallengeParam: action.payload.cognitoUser.ChallengeParam,
            }),
        );
    } catch (error: any) {
        yield put(selectCommunicationFailure(toAuthenticationError(error, SELECT_COMMUNICATION_ERROR)));
    }
}

export function* validateChallengeCodeOnCognito(
    action: PayloadAction<ValidateChallengeCodeOnCognitoPayload>,
): Generator<unknown, void, any> {
    try {
        const cognitoUser = toCognitoUser(action.payload.cognitoUser);
        const response = yield sendCustomChallengeAnswer(cognitoUser, action.payload.code);

        const user = fromCognitoUser(response);
        yield put(refreshCognitoUser({ ...user, ChallengeParam: action.payload.cognitoUser.ChallengeParam }));

        if (user.IsAuthenticated) {
            validateChallengeCodeSuccess(user.AccessToken);
            yield put(changeAuthState({ isAuthenticated: true, token: user.AccessToken, cognitoUser: user }));
        } else {
            yield put(validateChallengeCodeFailure(INVALID_CODE_ERROR));
        }
    } catch (error: any) {
        yield put(validateChallengeCodeFailure(toAuthenticationError(error, VALIDATE_CHALLENGE_ERROR)));
    }
}

export function* logoutFromCognito(action: PayloadAction<UnauthenticatPayload>): Generator<unknown, void, unknown> {
    try {
        yield put(resetSignInState());
        yield put(initiateAuth(action.payload.username));
        yield put(logoutSuccess());
        if (action.payload.showLogoutMessage) {
            yield put(sendNotification(LoggedOutNotificationMessage));
        }
        yield signOut();
    } catch (error: any) {
        yield put(logoutFailure(toAuthenticationError(error, LOGOUT_ERROR)));
    }
}

export function* genericSignInFailureHandler(
    action: PayloadAction<AuthenticationError>,
): Generator<unknown, void, unknown> {
    const lastError = action.payload;
    const hasError = !!lastError && lastError.name !== 'InvalidCode';

    if (!hasError) return;

    const isSessionExpired = isSessionExpiredError(lastError);
    const isSessionInvalid = isSessionInvalidError(lastError);
    const isTransientError = isSessionExpired || isSessionInvalid;

    if (!isTransientError) {
        yield put(unhandledError(action.payload));
        return;
    }

    const cognitoUser = (yield select(selectCognitoUser)) as CognitoUserType;
    const communicationMethod = (yield select(selectedCommunicationMethod)) as CommunicationMethod;

    if (isSessionExpired) {
        yield put(
            reinitializeAuth({
                username: cognitoUser.Username,
                selectedCommunicationMethod: communicationMethod,
                notificationMessage: SessionExpiredNotificationMessage,
            }),
        );
    } else {
        yield put(
            unAuthenticate({
                username: cognitoUser.Username,
                showLogoutMessage: true,
            }),
        );
    }
}

export function* reinitializeAuthOnErrorHandler(
    action: PayloadAction<AuthenticationError>,
): Generator<unknown, void, unknown> {
    const isSessionExpired = isSessionExpiredError(action.payload);

    if (isSessionExpired) {
        const cognitoUser = (yield select(selectCognitoUser)) as CognitoUserType;
        const communicationMethod = (yield select(selectedCommunicationMethod)) as CommunicationMethod;

        yield put(
            reinitializeAuth({
                username: cognitoUser.Username,
                selectedCommunicationMethod: communicationMethod,
            }),
        );
    } else {
        yield genericSignInFailureHandler(action);
    }
}

export function* watchSignIn(): Generator {
    yield takeEvery(initiateAuth, initiateAuthOnCognito);
    yield takeEvery(reinitializeAuth, reinitializeAuthOnCognito);
    yield takeEvery(selectCommunication, selectCommunicationMethodOnCognito);
    yield takeEvery(validateMagicLink, validateMagicLinkOnCognito);
    yield takeEvery(validateChallengeCode, validateChallengeCodeOnCognito);

    yield takeEvery(unAuthenticate, logoutFromCognito);
    yield takeEvery(initiateAuthFailure, genericSignInFailureHandler);
    yield takeEvery(selectCommunicationFailure, reinitializeAuthOnErrorHandler);
    yield takeEvery(reinitializeAuthFailure, genericSignInFailureHandler);
    yield takeEvery(validateChallengeCodeFailure, genericSignInFailureHandler);
    yield takeEvery(logoutFailure, genericSignInFailureHandler);
}

import { AnyAction, combineReducers, configureStore, getDefaultMiddleware, Reducer, Store } from '@reduxjs/toolkit';
import createSagaMiddleware, { Task } from 'redux-saga';
import { useDispatch } from 'react-redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootSaga from './sagas';
import { isDevelopment } from './config';
import { api } from './services/graphql/concert-base-api';
import { staticReducers } from './reducers';
import { emptyPcApi } from './services/empty-pc-api';

export interface ExtendedStore extends Store {
    injectReducer: (key: string | number, asyncReducer: Reducer) => void;
    injectSaga: (key: string, saga: () => Generator<unknown, unknown, unknown>) => void;
    asyncReducers: { [x: string]: unknown };
}

export default function configureAppStore(initialState = {}): ExtendedStore {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const middlewares = [sagaMiddleware, routerMiddleware(history), emptyPcApi.middleware, api.middleware];
    const store = configureStore({
        reducer: createReducer({ router: connectRouter(history) }),
        middleware: getDefaultMiddleware().concat(middlewares),
        preloadedState: initialState,
        devTools: isDevelopment(),
    }) as ExtendedStore;
    store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);
    store.asyncReducers = {};
    store.injectReducer = (key: string | number, asyncReducer: Reducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers) as Reducer<unknown, AnyAction>);
    };
    return store;
}

function createReducer(asyncReducers: { [x: string]: unknown }) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers,
    });
}

function createSagaInjector(
    runSaga: (s: () => Generator<unknown, unknown, unknown>) => Task,
    sagaToInject: () => Generator<unknown, unknown, unknown>,
) {
    const injectedSagas = new Map();

    const isInjected = (key: string) => injectedSagas.has(key);

    const injectSaga = (key: string, saga: () => Generator<unknown, unknown, unknown>) => {
        if (isInjected(key)) return;

        const task = runSaga(saga);
        injectedSagas.set(key, task);
    };

    injectSaga('root', sagaToInject);

    return injectSaga;
}

export const history = createBrowserHistory();
export const store = configureAppStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { Auth, Signer } from 'aws-amplify';
import { getAuthSettings, getGraphQLConfig, GraphQLSettings, isDevelopment } from '../../config';
import { API_RESCHEDULE_ERROR_MESSAGES } from '../../containers/appointments/appt-details/constants';
import { LoggedOutNotificationMessage } from '../../containers/sign-in/notification-message';
import { unAuthenticate } from '../../containers/sign-in/slice';
import { initializeAxiosConfigDefaults } from '../../service-client/http-interceptors';
import { updateCalendarEventCompleteStatusQueryData } from '../appt-details-api';
import { AppSyncAuthorizationHeaders, DEFAULT_GRAPHQL_URL, TOKEN_EXPIRED_MESSAGE } from './constants';
import { addError } from '../../containers/notification-container/slice';

initializeAxiosConfigDefaults();
const graphQLConfig: GraphQLSettings = getGraphQLConfig();

type GraphQLParametersType = {
    document: string;
    variables: unknown;
    endpoint: string;
};
export type RequestReschedulePayload = {
    id: string;
    patientComments: string;
};

export const isSessionExpiredError = (message?: string): boolean => !!message?.startsWith(TOKEN_EXPIRED_MESSAGE);

const signRequest = async (params: unknown, service: string, region: string) => {
    const essentialCredentials = Auth.essentialCredentials(await Auth.currentCredentials());

    const credentials = {
        secret_key: essentialCredentials.secretAccessKey,
        access_key: essentialCredentials.accessKeyId,
        session_token: essentialCredentials.sessionToken,
    };
    const serviceInfo = { region, service };
    return Signer.sign(params, credentials, serviceInfo);
};

const isAnonymousRequest = async (): Promise<boolean> => {
    try {
        const essentialCredentials = Auth.essentialCredentials(await Auth.currentCredentials());
        return !essentialCredentials.authenticated;
    } catch (e) {
        return false;
    }
};

const generateIamAuthHeader = async (headers: Headers, parameters: GraphQLParametersType): Promise<void> => {
    const authSettings = getAuthSettings();
    const queryString = parameters.document.replace(/\n/g, `\\n`);
    const variablesString = JSON.stringify(parameters.variables);
    const params = {
        headers: {},
        data: `{"query":"${queryString}","variables":${variablesString},"operationName":"${parameters.endpoint}"}`,
        method: 'POST',
        url: graphQLConfig.graphQLUrl,
    };
    const signedRequest = await signRequest(params, 'appsync', authSettings.region);

    for (const header in signedRequest.headers) {
        if (signedRequest.headers[header]) headers.set(header, signedRequest.headers[header]);
    }
};

const baseGraphQLQuery = graphqlRequestBaseQuery({
    url: graphQLConfig?.graphQLUrl ?? DEFAULT_GRAPHQL_URL,
    prepareHeaders: async (headers, api: any) => {
        const { signIn } = api.getState();

        if (isDevelopment()) {
            headers.set(AppSyncAuthorizationHeaders.Authorization, `${signIn.accessToken}`);
            headers.set(AppSyncAuthorizationHeaders.ApiKey, `${graphQLConfig.apiKey}`);
        } else if (signIn.accessToken) {
            headers.set('Authorization', `${signIn.accessToken}`);
        } else if (await isAnonymousRequest()) {
            await generateIamAuthHeader(headers, { ...api.extra, endpoint: api.endpoint });
        }

        return headers;
    },
});

export const api = createApi({
    reducerPath: 'graphQLApi',
    baseQuery: async (args, concertBaseApi: any, extraOptions): Promise<any> => {
        concertBaseApi.extra = args;
        const result = await baseGraphQLQuery(args, concertBaseApi, extraOptions);
        if (isSessionExpiredError(result?.error?.message)) {
            result.error = undefined;
            result.data = {};

            const { signIn } = concertBaseApi.getState();
            if (signIn.isAuthenticated) {
                await concertBaseApi.dispatch(
                    unAuthenticate({
                        username: signIn.username,
                        showLogoutMessage: false,
                    }),
                );
                await concertBaseApi.dispatch(addError({ customMessage: LoggedOutNotificationMessage.message }));
            }
        }
        if (result.error && args.variables?.rescheduleRequest?.originalAppointmentId) {
            result.error.message.includes(API_RESCHEDULE_ERROR_MESSAGES.APPOINTMENT_COMPLETED_VISIT) &&
                (await concertBaseApi.dispatch(
                    updateCalendarEventCompleteStatusQueryData(args.variables.rescheduleRequest.originalAppointmentId),
                ));
        }
        return result;
    },
    keepUnusedDataFor: 0,
    endpoints: () => ({}),
});

import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoUserType } from './slice';
import { parseCommunicationMethods } from '../auth/communication-methods/communication-methods';

export const fromCognitoUser = (cognitoUser: any): CognitoUserType => {
    let accessToken = '';

    if (typeof cognitoUser.getSignInUserSession === 'function') {
        accessToken =
            (cognitoUser as unknown as CognitoUser)?.getSignInUserSession()?.getAccessToken()?.getJwtToken() ?? '';
    } else {
        accessToken = cognitoUser.AccessToken as string;
    }

    return {
        Session: cognitoUser.Session,
        Username: cognitoUser.username,
        AccessToken: accessToken,
        IsAuthenticated: !!cognitoUser.signInUserSession,
        Pool: {
            ClientId: cognitoUser.pool.clientId,
            UserPoolId: cognitoUser.pool.userPoolId,
        },
        ChallengeParam: {
            CommunicationOptions: parseCommunicationMethods(cognitoUser.challengeParam?.communication_options),
        },
    };
};

export const toCognitoUser = (user: CognitoUserType): any => {
    const poolData = {
        UserPoolId: user.Pool.UserPoolId,
        ClientId: user.Pool.ClientId,
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
        Username: user.Username,
        Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    (cognitoUser as any).Session = user.Session;

    return cognitoUser;
};

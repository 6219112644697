import './crisis-footer.scss';

export const CrisisFooter = () => {
    return (
        <div className="crisis-footer">
            If you&apos;re in crisis, call or text <a href="tel:988">988</a>, or chat at{' '}
            <a href="https://988lifeline.org/" target="_blank" rel="noreferrer">
                988lifeline.org
            </a>
            . In case of medical emergency, call <a href="tel:911">911</a>.
        </div>
    );
};

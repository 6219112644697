import { DateTime } from 'luxon';
import { getAppSettings } from '../config';

export const maintenanceService = () => {
    const { message: maintenanceMessage, startDateTime, endDateTime } = getAppSettings()?.maintenanceSettings || {};

    const isUnderMaintenance = (): boolean => {
        const hasMessage = maintenanceMessage?.trim().length > 0;
        const maintenanceStartDateTime = (startDateTime ?? '').trim().length > 0 ? startDateTime : null;
        const maintenanceEndDateTime = (endDateTime ?? '').trim().length > 0 ? endDateTime : null;

        if (hasMessage && maintenanceStartDateTime && maintenanceEndDateTime) {
            const serverStartDateTime = DateTime.fromISO(maintenanceStartDateTime, { setZone: true });
            const serverEndDateTime = DateTime.fromISO(maintenanceEndDateTime, { setZone: true });
            const serverDateTimeNow = DateTime.now().setZone(serverEndDateTime.zone);
            return serverDateTimeNow >= serverStartDateTime && serverDateTimeNow <= serverEndDateTime;
        }
        return hasMessage;
    };

    return {
        maintenanceMessage,
        isUnderMaintenance,
    };
};
